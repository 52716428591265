import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Download({
    text,
    path,
    filename
}) {
    return(
        <Container className="download-link-container">
            <Row className="justify-content-sm-center download-link-row">
                <Col className="download-link-col" xs lg="3">
                    <a className={"download-link"} href={process.env.PUBLIC_URL + path} download={filename}>{text}</a>
                </Col>
            </Row>
        </Container>
    );
}

export default Download;
