import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Projects() {
    return (
        <Container className="gradient-border-projects">
            <Row>
                <Col id="projects-header">
                    <h4 id="projects-header">Projects</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row className="project-row">
                        <Col>
                            <a href="https://investifi.co" className="project-link" target="_blank">InvestiFi</a>
                        </Col>
                    </Row>
                    <Row className="project-row">
                        <Col>
                            <a href="https://www.partsgiant.com/" className="project-link" target="_blank">Parts Giant</a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Projects;
