import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


library.add(fab);

function Header() {
  return (
      <Container fluid className="header-container">
          <Row fluid>
              <Col>
                  <h1 id="my-name" className="gradient-border-top-left">Jake Williamson</h1>
              </Col>
              <Col xs lg={2} className="header-link-col">
                  <a className="App-link" href="mailto:brianjw88@gmail.com?subject=Mail From Portfolio">
                      <FontAwesomeIcon className="header-icon" icon={["fab", "google"]} size="2x"/>
                  </a>
                  <a className="App-link" href="https://github.com/jakewilliamson88" target="_blank">
                      <FontAwesomeIcon className="header-icon" icon={["fab", "github"]} size="2x"/>
                  </a>
                  <a className="App-link" href="https://www.linkedin.com/in/brian-williamson-448b9977" target="_blank">
                      <FontAwesomeIcon className="header-icon" icon={["fab", "linkedin-in"]} size="2x"/>
                  </a>
              </Col>
          </Row>
      </Container>
  );
}

export default Header;